.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.navBar{
    background-color: #282c34;
    list-style-type: none;
    margin: 0;
    padding: 0;
    padding-bottom: 10px;
    position: -webkit-sticky;
    position: sticky;
    top: -5px;
    outline: 1px white solid;
}

.navBar > li {
    display: inline-block;
    padding: 10px;
    padding-left:2%;
    padding-right:2%;
    border: 1px #282c34 solid;
}

.navBar a {
    color: #61dafb;
    padding: 10px;
    border-radius: 7.5px;
    text-decoration: none;
}

.navBar a.active {
    border: 1px white solid
}

.navBar a:hover {
    transition: background  .4s;
    background: #ffffff11;
}

.navBar .websiteTitle {
    border: 2px dotted white;
    font-size:20px;
    color: white;
    text-decoration: none;
    font-weight: bolder;
}

.navBar .websiteTitle img{
    transition: width 2s;
    width:40px;
    padding-right:10px;
}

.navBar .websiteTitle img:hover{
    transition: width 2s;
    width:60px;
    padding-right:10px;
}

.App-header p {
    max-width: 700px;
    padding: 20px;
}

@media only screen and (min-width: 801px) {
}

@media only screen and (min-width: 641px) and (max-width: 800px) {
}
 
@media only screen and (max-width: 640px) {
}

